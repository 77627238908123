.box {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 20.63rem;  //330px
	width: 100%;
	max-width: 100%;
	border-radius: .19rem;  //3px
	margin: 1em 0;

	&__title-section {
		z-index: 0; }

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		padding: 1rem;  //16px
		background-color: #fff; }

	&__button-container {
		position: relative;
		display: flex;
		flex-grow: 1;
		align-items: flex-end;
		bottom: -.63rem; }  //-10px

	&__menu-item {
		margin: .5rem 0; }

	&__wide-corner-menu {
		width: 15.63rem; }  //250px

	@include media(m, -100px) {
		max-width: 47%;
		flex-grow: 1; }

	.box__toolbar-section {
		align-items: center;

		&.mdc-toolbar__section--align-end {
			flex-grow: unset; } }

	.box__flex-title {
		flex-grow: 4;
		padding: 0; }

	.box__corner-menu li {
		position: relative;
		padding: 0 .63rem;  //10px
		min-width: 7.5rem;  //120px

		> * {
			width: 100%; } }

	.label {
		display: block;
		margin-bottom: .13rem; }  //2px

	&--long-content {
		width: auto;
		margin: 1.25rem auto 1.25rem 0;  //20px auto 20px 0

		@include media(m, -100px) {
			width: 100%;
			max-width: 100%; } }

	&--flexible {
		width: 100%;

		[class*='button'] {
			width: 100%;
			text-align: center;
			margin: 5px 0; }

		.ellipsis__button {
			width: auto;

			.rmwc-icon {
				width: auto; } } }

	&--selected {
		border: 3px solid $color-primary; }

	&--advertisement,
	&--half-width {
		.field-with-label {
			width: 50%; } } }
