.skip-link {
	display: block;
	position: fixed;
	left: -562.5rem;  //-9000px
	height: auto;
	padding: .25rem .5rem;  //4px 8px
	border: 2px solid #000080;
	font-size: .88rem;  //14px
	color: #000;
	background-color: #fff;
	text-align: center;
	z-index: 100;

	&:active,
	&:focus,
	&:hover {
		top: .25rem;  //4px
		left: 1rem; }

	&:active,
	&:focus {
		outline: $color-black solid 3px !important;
		-moz-outline: $color-black solid 3px !important;
		outline-offset: 2px !important;
		-moz-outline-offset: 2px !important; } }

