// Colors
$color-black: #000;
$color-border: rgba($color-black, .3);
$color-disabled: #f2f2f2;
$color-error: #eb0000;
$color-grey: rgba($color-black, .7);
$color-grey-lite: $color-grey;
$color-grey-xlite: #c5c5c5;
$color-primary: #0d47a1;
$color-success: #2e7225;
$color-white: #fff;

// Dimensions
$global-width: 100rem;
