.draft-to-components {
	img,
	iframe {
		display: block;
		max-width: 100%;
		margin: 1.25rem auto; }  //20px auto

	p + p:empty {
		display: block;
		height: 1.56rem; }  //25px

	&__blockquote,
	&__ol,
	&__ul {
		padding-left: 1.5rem; }  //24px


	&__blockquote {
		max-width: 37.5rem;  //600px
		margin: 1.88rem 0;  //30px
		font-style: italic; } }
