.container-with-wysiwyg .field-with-label:first-of-type {
	z-index: 9; }

.wysiwyg-wrapper {
	width: 100%; }

.wysiwyg {
	img,
	iframe {
		display: block;
		max-width: 100%;
		margin: 1.25rem auto; }  //20px auto

	&__editor {
		padding: 1rem 0;
		border-bottom: 1px solid $color-border;

		* {
			margin: 0; }

		&:hover {
			border-bottom-color: $color-grey; } }

	&__wrapper-with-toolbar .wysiwyg__editor {
		padding: 1rem;
		border: 1px solid $color-border;
		border-top: none; }

	&__counter {
		padding: .19rem 1rem;  //3px 16px
		font-size: .75rem;  //12px
		color: $color-grey; }

	.rdw-image-modal {
		z-index: 100; } }
