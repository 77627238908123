body {
	position: relative;
	margin: 0 auto;
	background-color: #fff;
	font-family: "Roboto", sans-serif;
	line-height: 1.5;
	box-sizing: border-box;
	overflow-x: hidden;

	* {
		overflow-wrap: break-word;
		word-break: break-word; } }

a {
	text-decoration: none;

	&[disabled],
	&:disabled {
		pointer-events: none; } }

button {
	border: none;
	background-color: transparent;
	word-break: normal;
	overflow-wrap: normal;
	cursor: pointer;

	&.link-text {
		text-align: left; } }

mark::before,
mark::after {
	content: 'Początek znalezionej frazy';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
	pointer-events: none;
	opacity: 0; }

mark::after {
	content: 'Koniec znalezionej frazy'; }

[class*="top-app-bar"] {
	top: 0;
	left: 0; }

.page-wrapper {
	position: relative;
	min-height: 100vh; }

.page-wrapper:has(.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing)) {
	margin-left: 270px; }

.main {
	padding: 1.5rem 1.5rem 4.38rem 1.5rem; }

.top-app-bar--desktop {
	padding-left: 270px; }

.header-user-name {
	transition: opacity .2s ease;
	padding: 0 .5rem;
	position: absolute;
	opacity: 0;

	@include media(xs, 100px) {
		position: relative;
		opacity: 1; } }

.main-page-title {
	color: #fff;

	&:hover {
		color: #fff; }

	&:active,
	&:focus {
		outline: $color-white solid 3px !important;
		-moz-outline: $color-white solid 3px !important;
		outline-offset: 2px !important;
		-moz-outline-offset: 2px !important; } }

.menu-basic {
	margin-bottom: 1.25rem;

	&__header {
		padding: .5rem 1.25rem 0; }

	@include media(l) {
		width: 17.5rem;
		margin-right: 1.25rem; } }

.login-info-container {
	margin: 1.88rem 0;
	text-align: right; }

.login-info-text {
	margin-bottom: 1rem; }

.company-meta {
	flex-wrap: wrap;

	.field-with-label {
		min-width: 40%;
		width: 40%; } }

.audit-meta {
	flex-wrap: wrap;

	@include media(m) {
		.field-with-label {
			max-width: 15.625rem; } } } // 250/16

.author-label {
	padding: .13rem .63rem;
	border-radius: .13rem;
	margin-left: 10px;
	font-size: .75rem;
	color: #fff;
	background-color: $color-grey;
	text-transform: uppercase; }

.instruction {
	margin-bottom: 2rem;

	.text {
		display: flex;
		align-items: center;
		margin-bottom: .63rem;

		> * {
			margin-right: .19rem; } } }

.checkbox {
	margin: 1.88rem 0;

	label {
		font: {
			size: 1rem;
			weight: 700; } }

	&--inside {
		margin-top: .63rem; }

	&--btn {
		min-width: 13.44rem;
		max-height: 36px;
		padding: 0 .94rem 0 .31rem;
		border: 2px solid $color-primary;
		border-radius: 5px;
		margin-bottom: .5rem;  //8px

		label {
			font: {
				size: .88rem;
				weight: 500; }
			line-height: 1.31rem;
			letter-spacing: .08rem;
			color: $color-primary;
			text-transform: uppercase; } }

	&--btn-primary {
		display: flex;
		justify-content: center;
		max-width: 100%;
		border: none;
		background-color: $color-primary;
		text-align: center;

		label {
			margin: 0;
			color: $color-white; }

		.mdc-checkbox__background {
			border-color: rgba($color-white, .8) !important; } } }

.link-text,
.success-text {
	font: {
		size: 1rem;
		weight: 700; }
	color: $color-success;
	text-decoration: underline;
	cursor: pointer; }

.link-text--primary {
	color: $color-primary;
	font-weight: 400; }

.success-text {
	text-decoration: none; }

.long-text {
	white-space: pre-line; }

.form__container {
	padding: 1rem;
	border-radius: .19rem;
	background-color: #fff; }

.field-with-label {
	position: relative;
	margin: 1rem 0 1.25rem;

	&--one-line {
		min-width: 19.38rem;
		margin-bottom: -.25rem; }

	&.disabled .react-datepicker__close-icon {
		opacity: .5;
		pointer-events: none; } }

.separate-content {
	padding-bottom: .75rem;
	border-bottom: 1px solid $color-border;
	margin-bottom: .75rem; }

.separate-container {
	margin-top: 1.25rem; }

.separate-part,
.mr-counter {
	margin-bottom: .63rem; }

.label {
	margin-bottom: .5rem;
	font-weight: 600;
	color: #000;

	&:empty {
		margin-bottom: 0; } }

.corner-button {
	margin: 0 0 1rem 0; }

.drag-n-drop-input {
	display: block;
	position: relative;
	height: 100%;
	height: 8.13rem;
	margin-bottom: .63rem; }

.drag-n-drop-input-label {
	display: block;
	height: 8.25rem;
	font-size: 1rem;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: .63rem 1.25rem;
	border: 4px dashed $color-primary;
	color: $color-primary;
	background-color: #fff;
	pointer-events: none;
	text-align: center; }

.attachements {
	&__header {
		margin-top: 1rem;
		line-height: 1.5; }

	&__list {
		margin-bottom: 2rem; }

	&__download-item a {
		margin-left: .5rem; } }

.landing-page-contacts {
	@include media(m, -100px) {
		margin-bottom: 1rem;

		.grid-custom__cell {
			position: relative;
			min-width: 21.88rem;
			flex-grow: 1;

			.container {
				height: 100%;
				margin: 0; }

			&:first-of-type {
				margin-right: 1.5rem; } } } }

.advertisements-list {
	width: 100%; }

.advertisement-form-save {
	align-items: flex-end; }

.session-timer {
	white-space: nowrap; }

.tab-bar {
	margin-top: 3.125rem; } // 50/16

.code-input-container {
	.text {
		flex-shrink: 0;
		padding-top: .875rem; // 14/16
		margin-right: .625rem; }

	&--edition .text {
		padding-top: 0;
		margin-right: 0; }

	&__first-part {
		font-weight: 400 !important; } }

#root {
	.menu-item {
		height: 55px;
		margin-top: .2rem;
		&:active,
		&:focus {
			outline: $color-black solid 3px !important;
			-moz-outline: $color-black solid 3px !important;
			outline-offset: 2px !important;
			-moz-outline-offset: 2px !important; } } }


// Only for screen readers
.sr-only {
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
	pointer-events: none;
	opacity: 0; }

// Content management
.cookies-container {
	position: fixed;
	align-items: flex-start;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: flex-start;
	z-index: 60;
	padding: 1.25rem;
	background-color: $color-grey-xlite;

	.mdc-button {
		min-width: 7.5rem;
		margin: 0 0 0 1.25rem; } }

.content-manage {
	flex-wrap: wrap;

	&__item {
		width: 12.5rem;
		margin: .63rem; }

	&__icon {
		padding: 1rem 1rem 0 1rem;
		color: $color-primary;
		text-align: center; }

	&__text {
		padding: 0 1rem 1rem 1rem;
		color: #000;
		text-align: center; } }

.top-app-bar-imitation {
	position: relative;
	height: 4.06rem;
	padding-left: 1.5rem;
	margin: 0 -1.5rem 1.5rem;
	font-size: 1.25rem;
	line-height: 4.06rem;
	color: #fff;
	background-color: $color-primary; }

.users {
	&__box,
	&__container {
		width: 100%;

		.box__title-section {
			padding: 1rem; }

		@include media(m) {
			min-width: 45%; } }

	&__container {
		margin: .63rem;  //10px

		&--middle {
			margin: .63rem auto 1.25rem; } }  //10px auto 20px

	&__id {
		font-size: .88rem;  //14px
		color: $color-grey; } }

.session-timer {
	display: flex;
	align-items: center;
	justify-content: center; }

.more-options {
	&__btn {
		display: block;
		max-height: 2.5rem; // 40/16
		margin-top: -.375rem; // 6/16
		color: $color-grey !important;
		background-color: transparent !important;

		&:focus {
			color: $color-primary; } }

	&__icon {
		color: $color-grey; }

	&__menu {
			min-width: 15.625rem; // 250/16
			width: 20.625rem; } } // 330/16

.fetch-error-with-ok-btn {
	margin-top: 1.25rem; } // 20/16

.filters-dialog {
	&__filters-section {
		margin-bottom: 5rem;  //80px

		.field-with-label {
			min-width: 48%; } }

	&__filters-headers {
		margin-top: .625rem; // 10/16
		color: $color-grey; }

	&__advertisement-filters-base {
		align-items: baseline; }

	&__advertisement-adress-fields {
		display: flex;

		.field-with-label {
			margin-right: 1rem;

			&:last-child {
				margin-bottom: 15px; } } }

	&__advertisement-adress-type {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.select-custom {
			flex-grow: 0; } } }

.fetch-btn {
	font: {
		size: 1rem;
		weight: 700; }
	text-decoration: underline;

	&--error {
		color: $color-error; }

	&--info {
		color: $color-primary; } }

.pagination {
	margin: 1.25rem auto;

	&__current {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1.875rem; // 30/16
		min-width: 1.875rem; // 30/16
		border-radius: 1.875rem; // 30/16
		background-color: $color-disabled; } }

// Vendor overrides
.material-icons {
	vertical-align: bottom; }

.text-field-custom-container {
	position: relative;
	width: 100%; }

.text-field-clear {
	position: absolute;
	top: 1rem;
	right: .5rem;
	color: $color-primary;

	&:hover,
	&:active,
	&:focus {
		&::after {
			content: 'Wyczyść pole';
			display: block;
			width: 6.25rem;  //100px
			position: absolute;
			top: -1.88rem;  //-30px
			right: -.63rem;  //-10px
			padding: .31rem .63rem;  //5px 10px
			border-radius: .19rem;  //3px
			color: #fff;
			background-color: #000;
			z-index: 1;
			font: {
				size: .88rem;  //14px
				family: 'Roboto', sans-serif; } } } }

.prevent-autofocus-trap-el {
	position: absolute; }

.published-version {
	&__item {
		padding-bottom: .8rem;
		border-bottom: 1px dashed rgba($color-black, .3);
		margin-bottom: .8rem; }

	&__author-info {
		display: block;
		width: 100%;
		padding-left: .8rem;
		font: {
			size: .875rem;
			weight: 700;
			style: italic; }
		color: rgba($color-black, .6); } }

.subscription-icon {
	font-size: 8rem;
	color: $color-primary;

	&__success {
		color: green;
		font-size: 8rem; } }

.switch-nonboldtext {
	.label {
		font-weight: 400; } }
