.users {
	&__box,
	&__container {
		width: 100%;
		max-width: 100%;

		.box__title-section {
			padding: 1rem; }

		@include media(m) {
			min-width: 45%; } }

	&__container {
		margin: .63rem;  //10px

		&--middle {
			margin: .63rem auto 1.25rem; } }  //10px auto 20px

	&__id {
		font-size: .88rem;  //14px
		color: $color-grey; } }
