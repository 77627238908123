.administration,
.permissions {
	&__main-name,
	&__level-name b {
		font-weight: 700; }

	&__sub-name {
		font-size: .875rem; // 14/16
		color: $color-grey; }

	&__indented {
		position: relative;
		padding-left: 1.5rem;

		&::before {
			content: 'subdirectory_arrow_right';
			position: absolute;
			left: 0;
			margin-right: .5rem;
			font-family: 'Material Icons'; }

		&--on-start {
			padding-left: 1.25rem; // 20/16
			margin-left: 1.25rem; } } // 20/16

	&__level-1 {
		padding-left: 2rem;

		label {
			font-weight: 700; } }

	&__level-2 {
		padding-left: 4rem; }

	&__col-permissions-details {
		width: 26rem;
		max-width: 26rem; }

	&__col-permissions-list {
		width: 65%;
		max-width: 65%; }

	&__col-buttons {
		width: 14.0625rem; // 225/16
		max-width: 14.0625rem; } // 225/16

	&__history {
		margin-bottom: 1.875rem; } // 30/16

	&__history-filter-btn {
		top: 3.125rem; } // 50/16

	&__level-row {
		display: flex;
		align-items: flex-start;
		width: 100%;
		margin-left: -1.25rem; // 20/16

		.more-options__btn {
			padding: 0;
			margin: -.125rem .5rem 0 0; }

		.text {
			margin-top: .1875rem; } // 3/16

		.permissions__level-row {
			margin-left: 0; } }

	&__show-more,
	&__show-less {
		&.mdc-button {
			margin: 0 0 0 .625rem;
			font-size: .875rem;
			letter-spacing: 0; }

		.mdc-button {
			&__icon {
				margin-right: 0; }

			&__label {
				text: {
					decoration: underline;
					transform: none; } } } }

	&__show-less.mdc-button {
		color: $color-grey; }

	&__legend {
		&::before {
			content: 'visibility';
			position: relative;
			margin-right: .5rem;
			font-family: 'Material Icons'; }

		&--projects::before {
			content: 'account_tree'; }

		&--permissions::before {
			content: 'group'; } }

	&__level-list {
		margin: .625rem 0;

		.permissions__level-list .permissions__indented {
			margin-left: 1.8rem; }

		.permissions__level-row--deeper .permissions__indented {
			margin-left: 3.5rem; }

		.text--error {
			margin-left: 6.5rem; } }

	&__more-options.mdc-button {
		display: block;
		position: relative;
		margin: {
			left: auto;
			right: auto; } }

	&__more-options-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center; } }

.administration {
	&__header-links:not(:empty) {
		margin-bottom: 3rem; }

	&__header-link {
		display: block;
		margin-bottom: .5rem; }

	&__table-link {
		white-space: pre-wrap; }

	&__code {
		position: relative;
		margin-right: .5rem; } }

