.btn {
	&.btn--main {
		margin: .31rem 0;

		@include media(m) {
			padding: .8rem 1.5rem;
			font-size: 1rem; } }

	&.btn--save-main {
		display: block;
		margin-left: auto; }

	&--pdf-dowload {
		min-width: 9.38rem; }

	&--delete {
		min-width: 6.25rem; }

	&--cancel {
		background-color: $color-grey !important; }

	&--primary {
		background-color: $color-primary !important; }

	&--icon-only {
		display: flex;
		min-width: 0;
		max-width: 3rem;
		height: 3rem;
		border-radius: 100%;

		.mdc-button__label {
			display: flex;
			justify-content: center; }

		&::active,
		&::focus,
		&::hover {
			background-color: rgba(#fff, .3) !important; } }

	&--counter {
		display: inline-block;
		width: 1.375rem; // 22/16
		height: 1.375rem; // 22/16
		border-radius: 100%;
		margin-left: .5rem;  //8px
		text-align: center;
		font: {
			size: 12px;
			weight: 600; }
		line-height: 1.375rem; // 22/16
		color: #fff;
		background-color: $color-error;
		z-index: 2; }

	&--counter-primary {
		background-color: $color-primary; } }

.btn-container-bottom {
	margin-top: 2rem; }
