.footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;

	ul {
		background-color: $color-grey; }

	@include media(l, 450px) {
		margin: {
			left: calc((100vw - #{$global-width}) / -2);
			right: calc((100vw - #{$global-width}) / -2); } }

	&__list {
		display: flex;
		justify-content: center; }

	.footer__list-item {
		display: block;
		margin: 1rem;  //16px
		color: #fff;

		&:active,
		&:focus {
			outline: $color-white solid 3px !important;
			-moz-outline: $color-white solid 3px !important; } } }
