.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  background-color: #EA0001;
  font-size: 18px;
  color: #FFFFFF;
  margin: auto 0px; }
