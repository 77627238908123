.select-custom {
	flex-grow: 1;
	min-width: 12.5rem;  //200px
	margin-bottom: 1.25rem;  //20px

	* {
		font-size: 1rem; }

	[class*='control'] {
		position: relative;
		min-height: 3.5rem;  //56px
		border: none;
		box-shadow: none;
		border-bottom: 1px solid $color-border;
		border-radius: .19rem .19rem 0 0;  //3px 3px 0 0

		&:hover {
			border-bottom-color: $color-grey; }

		&:focus {
			border: none;
			border-bottom: 1px solid $color-primary; } }

	[class*='multiValue'] * {
		white-space: normal;
		word-break: break-word; }

	[class*='menu']::before {
		content: '';
		display: block;
		height: .13rem;  //2px
		width: 100%;
		margin-top: -.63rem;  //-10px
		margin-bottom: .31rem;  //5px
		background-color: $color-primary; }

	&--invalid {
		margin-bottom: 0;

		> [class*='control'] {
			border-bottom-color: $color-error;

			&:active,
			&:focus,
			&:hover {
				border-bottom-color: $color-error; } } }

	&--long {
		min-width: 16.25rem; }  //260px

	&--download {
		margin: {
			right: .63rem;  //10px
			bottom: 0; }

		@include media(m) {
			max-width: 37.5rem; } }  //600px

	&__icon {
		color: $color-primary;
		font-size: 1.5rem; } // 24/16

	.text-field-clear {
		position: static; } }

.select-download-wrapper {
	align-items: flex-start;
	min-height: 12.5rem; } // 200/16
