.article {
	&__header {
		margin-bottom: 1.88rem; }  //30px

	&__list,
	&__text {
		max-width: 43.75rem;  //700px
		margin: 0 0 .94rem; }  //0 0 15px

	&__list {
		padding-bottom: .94rem;  //15px
		border-bottom: 1px solid #ccc; }

	&__list-item {
		display: flex;
		margin-bottom: .31rem;  //5px

		p {
			font-size: .88rem; } }  //14px

	&__section-title {
		font-weight: 600; }

	&__address {
		margin-bottom: .94rem;  //15px
		font-style: italic; } }
