// If you want to override default mdc-.... components styles, do it here

#root {}

.mdc-top-app-bar {
	position: relative;
	z-index: 10;

	&--fixed-adjust {
		display: none; }

	&__title {
		overflow: initial; }

	@include media(l) {
		position: fixed;

		&--fixed-adjust {
			display: block; } }

	&__row {
		height: auto;

		@include media(m, -100px, max-width) {
			flex-wrap: wrap; } } }

.mdc-drawer--dismissible {
	position: fixed;
	top: 0;
	box-shadow: 0 8px 10px 5px rgba(#000, .2); }

.mdc-list {
	font-size: 1rem;
	padding-top: 0; }

.mdc-list-item {
	&__graphic {
		margin-right: .5rem; }

	&--disabled {
		cursor: default;
		opacity: .8; }

	&__text {
		text-overflow: initial;
		white-space: normal; } }

.mdc-typography--body2 {
	font-size: 1rem;
	letter-spacing: 0; }

.mdc-typography--subtitle2 {
	font-size: 1rem; }

.mdc-text-field {
	&__input {
		padding-right: 2.8125rem !important; // there's no other way to override rmwc styles for input padding :(
		border-bottom: 1px solid white;

		&::-ms-clear {
			display: none; } }

	&--textarea {
		border-bottom: 1px solid $color-border;

		textarea {
			padding-right: 2.8125rem;
			background-color: transparent;

			&:focus {
				background-color: transparent; } }

		&:hover {
			border-bottom: 1px solid $color-grey; }

		.mdc-line-ripple--active {
			bottom: -1px; } }

	&.mdc-text-field--disabled {
		input {
			background-color: rgba($color-black, .02); }

		~ .text-field-clear {
			display: none; } }

	&__input {
		background-color: #fff; }

	&--with-trailing-icon {
		overflow: visible;

		.mdc-text-field__icon {
			right: -.31rem; } }

	&.mdc-ripple-upgraded {
		&::before,
		&::after {
			display: none; } } }

.mdc-line-ripple--active {
	height: 2px; }

.mdc-select__native-control {
	background-color: #fff; }

.mdc-drawer-scrim {
	z-index: 50; }

.mdc-drawer {
	z-index: 55;
	width: 270px; }

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing)+.mdc-drawer-app-content {
	margin-left: auto;
	margin-right: auto; }

.mdc-drawer-app-content {
	max-width: 100rem;
	margin-left: auto;
	margin-right: auto;
	max-width: 1300px; }

.mdc-drawer--modal {
	top: 0; }

.mdc-button {
	height: auto;
	min-height: 2.25rem;
	margin: .31rem;
	text-align: center;

	&.button--no-margin {
		margin: .31rem 0; }

	&:active,
	&:focus,
	&:hover {
		&::before,
		&::after {
			opacity: 0 !important; } } }

// Focus outline
.mdc-button,
.link-text,
.select-custom {
	&:active,
	&:focus {
		outline: $color-black solid 3px !important;
		-moz-outline: $color-black solid 3px !important;
		outline-offset: 2px !important;
		-moz-outline-offset: 2px !important; } }

.btn--icon-only {
	&:active,
	&:focus {
		outline: $color-white solid 3px !important;
		-moz-outline: $color-white solid 3px !important; } }

.mdc-toolbar {
	.mdc-toolbar__row {
		min-height: 1.88rem;
		justify-content: space-between;

		.mdc-toolbar__title,
		.mdc-typography--subtitle2 {
			margin-left: .5rem; } }

	.mdc-toolbar__title {
		padding: 0;
		font-size: 1rem; }

	.mdc-toolbar__section {
		padding: 0 0 0 .63rem;

		&--align-start {
			max-width: 31.25rem; }  //500px

		&--align-end {
			min-width: 5rem; }  //80px

		.mdc-typography--subtitle2 {
			margin-left: 0; } } }

.mdc-text-field__icon:empty {
	display: none; }

.mdc-menu {
	min-width: 15.63rem; }

.mdc-dialog {
	z-index: 11;

	.mdc-dialog__content,
	.mdc-dialog__surface {
		overflow: auto;
		color: $color-black;

		@include media(m) {
			overflow: visible; } }

	.mdc-dialog__surface {
		padding: .63rem;
		max-height: 98%;

		.mdc-button {
			margin-top: .63rem; }

		@include media(s) {
			min-width: 37.5rem;
			min-height: 6.25rem; }

		@include media(m) {
			height: auto;
			min-width: 50rem; } }

	.dialog-error {
		font: {
			size: 1.2rem;
			weight: 600; }
		color: $color-error; } }

.mdc-tab-bar {
	margin-bottom: 1.25rem; }

.mdc-tab {
	border-bottom: 1px solid $color-border;

	&:not(:last-child) {
		margin-right: .63rem; }

	&.mdc-ripple-upgraded {
		&:disabled {
			cursor: default; } }

	&:disabled {
		color: $color-black;
		background-color: $color-disabled;

		&:hover {
			background-color: $color-disabled; } } }

.rmwc-data-table {
	overflow: initial;

	&__cell {
		vertical-align: top; }

	&__row:hover .ellipsis__mask {
		background-image: linear-gradient(to right, rgba(#f6f6f6, 0), #f6f6f6); } }
