.ellipsis-container {
	position: relative; }

.ellipsis {
	position: relative;
	text-align: right;
	z-index: 1;

	&__mask {
		display: block;
		width: 5rem;
		margin-left: auto;
		background-image: linear-gradient(to right, rgba(#fff, 0), #fff); }

	&__button {
		min-height: 0;
		font: {
			size: 0.8rem;
			weight: 700; } } }
