.container {
	position: relative;
	border-radius: .19rem;  //3px
	margin: 0 auto 1.5rem;  //24px

	&__content {
		padding: 1rem; }  //16px

	.label {
		display: block;
		margin-bottom: .13rem; }  //2px

	&--no-border {
		box-shadow: none;

		.container__content {
			padding: 0; } } }
