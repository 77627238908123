.manage-lock {
	border-bottom: 2px solid $color-grey;
	margin-bottom: 1.875rem; // 30/16

	&__user {
		flex-grow: 1; }

	&__user-data {
		font-size: .875rem; // 14/16
		color: $color-grey;

		&--email {
			margin-bottom: 1rem; } }

	&__buttons {
		flex-grow: 1;
		margin-bottom: 1.875rem; // 30/16

		@include media(l) {
			max-width: 28.125rem; // 450/16
			padding-top: 2.1875rem; // 35/16
			margin-bottom: 0; } } }
