.search {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		height: 115%;
		width: 190%;
		right: -6.25rem;  //-100px
		bottom: -14.375rem; // 230/16
		transform: skew(-80deg);
		background-color: $color-primary;

		@include media(s) {
			width: 150%; }

		@include media(m) {
			width: 130%; }

		@include media(l) {
			top: 50%;
			width: 30%;
			transform: translateY(-50%) skew(-30deg); } }

	@include media(s) {
		padding: 2rem; }

	&__form {
		position: relative;
		text-align: center;
		margin-bottom: 2.5rem;

		.search__btn {
			margin-top: 2.5rem; }

		@include media(l) {
			margin-bottom: 0; } }

	&__input-container {
		display: flex;
		flex-direction: column-reverse; }

	&__examples {
		display: flex;

		* {
			margin-right: .31rem; } }  //5px

	&__input {
		width: 100%;
		margin-bottom: .31rem; }  //5px

	&__btn {
		height: auto;
		min-height: 2.81rem;  //45px
		min-width: 150px;
		font-size: .875rem; // 14/16

		@include media(s, -100px) {
			font-size: 1.125rem; } } // 18/16

	&__secondary {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media(l) {
			align-items: flex-end; } }

	&__item .link-text:visited {
		color: mix($color-primary, #609); }

	&__info {
		display: inline;
		color: #fff;
		background-color: $color-primary;

		@include media(l) {
			margin-right: .31rem;  //5px
			text-align: right; } }

	&__options {
		align-items: flex-end;
		margin: {
			top: -1rem;
			bottom: 1.5rem; }
		z-index: 8;

		.field-with-label {
			margin-bottom: .19rem; }  //3px

		.field-with-label:not(.search__select-sorter) {
			flex-grow: 0; } }

	&__results-meta {
		font-size: .75rem;  //12px
		margin: .5rem .5rem 0 0;
		text-transform: uppercase;

		&:last-child {
			margin-right: 0; }

		&--company-name {
			max-width: 65%; } } }
