.details-preview {
	@include media(m, 100px) {
		&__part--aside {
			width: 21.88rem;  //350px
			min-width: 21.88rem; } }  //350px

	@include media(l) {
		&__part--main {
			min-width: 70%; }

		&__part--aside {
			width: 27%;
			min-width: 27%;
			padding-left: 1rem;  //16px
			border-left: 1px solid #e5e5e5;
			margin-left: 1rem; } } }  //16px
