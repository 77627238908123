.text {
	line-height: 1.6;

	&--accent-header {
		font-weight: 700;
		color: $color-primary; }

	&--btn {
		font: {
			size: .88rem;  //14px
			weight: 500; }
		letter-spacing: .08rem;  //1.25px
		line-height: 1.31rem;  //21px
		color: $color-primary;
		text-transform: uppercase; }

	&--main-header,
	&--section-header {
		font: {
			size: 1.5rem;
			weight: 700; } }

	&--main-header {
		margin: 0 0 1.25rem; }  //20px

	&--main-title {
		font-size: 1.5rem; }  //24px

	&--title {
		margin-bottom: .63rem;  //10px
		font-weight: 700; }

	&--project-info {
		margin-right: .63rem; }  //10px

	&--disabled {
		color: transparent; }

	&--counter {
		margin-bottom: 1.25rem; } } // 20/16
