.filters-container {
	position: relative;
	z-index: 9;

	&__filters {
		text-align: center; } }

.filters {
	&__input {
		width: 100%; }

	@include media(l) {
		min-width: 18.75rem;  //300px
		width: 20.63rem;  //330px

		&.container {
			padding: 0;
			margin: .63rem .63rem 0 0;  // 10px 10px 0 0

			.field-with-label {
				box-sizing: border-box;
				width: 100%; } } } }
