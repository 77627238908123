.ogloszenie-progress-title {
	padding: .63rem; }  //10px

.ogloszenie-progress-list {
	li > span {
		display: inline-block;
		position: relative; }

	&__button {
		width: 100%;

		&:active,
		&:focus {
			outline: $color-white solid 3px !important;
			-moz-outline: $color-white solid 3px !important; } } }

