.offer {
	&__btn-container {
		margin-bottom: 1.25rem; }  //20px

	&__part-name {
		cursor: pointer;

		&--offer-details {
			margin-bottom: 1.5rem;

			.label {
				margin-top: .5rem;  //8px
				padding-left: 1.8rem; } }

		&--no-offer {
			padding-left: .9rem;

			span {
				color: $color-primary;
				text-transform: uppercase; } } }

	&__count {
		margin: .75rem .63rem .63rem; }

	&__box .field-with-label {
		min-width: 40%; }

	&__status {
		margin: 2rem auto 1.5rem;
		text-align: center; }

	&__status-icon {
		margin-right: 1rem;
		font-size: 1.88rem; }  //30px

	&__status-name {
		font: {
			size: 1.2rem;
			weight: 700; }
		text-transform: uppercase; }

	&__list {
		margin-bottom: 1.88rem; }  //30px

	&__my-offer-label {
		position: absolute;
		top: .31rem;  //5px
		right: .31rem;  //5px
		padding: .13rem .5rem;  //2px 8px
		border-radius: .19rem;  //3px
		font: {
			size: .63rem;  //10px
			weight: 700; }
		text-transform: uppercase;
		color: $color-primary;
		background-color: #fff; }

	&__box-hidden-text {
		width: 50%;
		height: 1.25rem;  //20px
		margin-bottom: .63rem;  //10px
		background-color: rgba($color-black, .1);

		&:nth-child(2n) {
			width: 100%; } } }

.variant {
	margin: .31rem 0;

	&__price {
		font-size: 1.13rem; }  //18px

	&__content {
		padding-left: 1.2rem; } }  //25px

.offer-resolve {
	&__container {
		@include media(l) {
			min-width: 43.75rem;  //700px
			flex-grow: 1; }

		@include media(1280px) {
			min-width: 41.25rem; }  //660px

		.checkbox--btn {
			margin: 1.88rem auto; }  //30px

		.checkbox--btn-primary {
			margin: .31rem; } }  //5px

	&__summary {
		padding-bottom: 1.25rem;  //20px
		border-bottom: 1px dashed $color-border;
		margin-bottom: 1.25rem; }  //20px

	&__name {
		margin: 1.88rem 0 1.25rem;  //30px 0 20px

		.label {
			margin-bottom: 0; } } }
