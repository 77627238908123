.date-time-picker {
	margin: .63rem 0;  //10px

	&__checkbox label {
		font: {
			size: 1rem;
			weight: 600; } }

	.field-with-label {
		min-width: 17.5rem; } }  //280px

.react-datepicker-wrapper {
	width: 100%;

	* {
		width: 100%; }

	input[type="text"] {
		min-width: 100%; }

	.react-datepicker__close-icon {
		width: auto;
		height: 1.88rem;  //30px
		margin-top: .75rem;  //12px

		&::after {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.44rem;  //23px
			line-height: 1rem;
			background-color: $color-primary; }

		&:active,
		&:focus {
			outline: $color-black solid 3px !important;
			-moz-outline: $color-black solid 3px !important;
			outline-offset: 2px !important;
			-moz-outline-offset: 2px !important;

			&::before {
				content: 'Wyczyść pole';
				display: block;
				width: 6.25rem;  //100px
				position: absolute;
				top: -1.88rem;  //-30px
				right: -.63rem;  //-10px
				padding: .19rem .63rem;  //3px 10px
				border-radius: .19rem;  //3px
				color: #fff;
				background-color: #000;
				z-index: 1;
				font: {
					size: .88rem;  //14px
					family: 'Roboto', sans-serif;
					weight: 300; } } } } }

.react-datepicker__day {
	&--keyboard-selected {
		background-color: $color-primary; }

	&--disabled {
		color: $color-black;
		background-color: $color-disabled;

		&:hover {
			background-color: $color-disabled; } } }
