.message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: .625rem; // 10/16

	@include media(s) {
		padding: 3.13rem; }  //50px

	&__icon {
		font-size: 5rem;  //80px
		color: $color-primary; }

	&__title {
		margin: .63rem auto;  //20px
		font: {
			size: 1.5rem;  //24px
			weight: 700; }
		text-align: center; }

	&__additional-info {
		font-size: 1.13rem;  //18px
		text-align: center;

		.link-text {
			font-size: 1.13rem; } }  //18px

	&--error .message__icon {
		color: $color-error; }

	&--success .message__icon {
		color: $color-success; }

	.text {
		color: $color-black; } }
