.progress-bar {
	position: relative;
	padding: 0 2.38rem 0 2.19rem;  //0 38px 0 35px
	margin-bottom: 2rem;  //16px

	&__progress-info {
		position: absolute;
		top: -.38rem;  //-6px
		left: .19rem;  //3px
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-size: 1rem;
		color: $color-grey; }

	.mdc-linear-progress__buffer, {
		background-color: #a8a8a8; } }
