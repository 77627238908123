.time-line {
	&__time-container {
		margin: 0 0 .31rem 1rem;  //0 0 5px 16px
		border-left: 1px dashed #000; }

	&__icon {
		width: 1.25rem;  //20px
		margin-right: .63rem; }  //10px

	.grid-custom {
		margin-bottom: 0; } }
