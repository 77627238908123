.questions-container {
	.text--main-header {
		margin: 0;

		+ .text {
			margin-bottom: 1.25rem; } } }  //20px

.questions-list-container:not(:last-child) {
	margin-bottom: 3.5rem; }

.question {
	&:last-of-type {
		margin-bottom: 0; }

	.container__content {
		padding-top: 1px; }

	.author-label {
		width: 5.63rem;  //90px
		margin: 1rem 0;
		text-align: center; }

	&__answer {
		position: relative;
		padding-left: 3.13rem; }  //50px

	&__answer-icon {
		position: absolute;
		top: -1.2rem;
		left: 0;
		font-size: 3.13rem;  //50px
		color: lighten($color-border, 50%);
		pointer-events: none; }

	&__original-content {
		padding-left: 2rem;
		margin-bottom: .5rem;
		font: {
			size: .75rem;  //12px
			style: italic; }
		color: $color-grey;

		&--header {
			padding-left: 2rem;
			margin-top: .5rem;
			font: {
				size: .75rem;  //12px
				weight: 700;
				style: normal; }
			color: $color-success; } }

	&__label {
		margin-top: 1rem;
		font-weight: 700;
		color: $color-black; } }
