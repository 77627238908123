.table {
	position: relative;
	max-width: 100%;
	margin: 0;

	* {
		word-break: break-word; }

	.text {
		white-space: normal; }

	&__header {
		font-weight: 600;
		color: $color-black; }

	&__secondary-info {
		margin-top: 1rem;
		font-size: .88rem;
		color: $color-grey; }

	&--full-width {
		width: 100%; }

	&--administration {
		&__meta {
			min-width: 12.5rem; // 200/16

			.ellipsis {
				&__button {
					display: none; }

				&__mask {
					margin-bottom: -15px; } } }

		&__user-info,
		&__user-id {
			color: $color-grey; }

		&__user-info.last-change {
			padding-left: .625rem; } // 10/16

		&__user-id {
			min-width: 15.3125rem; // 245/16
			padding: .75rem; } // 12/16

		&__user-menu-list {
			button {
				width: 100%; }

			li {
				padding: 0 .3125rem; } } // 5/16

		&__action-col,
		&__type-col {
			display: flex; }

		&__action-col {
			justify-content: space-between; }

		&__type-col {
			align-items: center;

			.text {
				margin-left: .625rem; // 10/16
				white-space: nowrap; }

			&.type-cancelled i {
				transform: rotate(90deg); } } }

	.rmwc-data-table {
		&__content {
			width: 100%;
			border-collapse: initial;

			td {
				border-bottom: 0; } }

		&__cell {
			min-width: 9.38rem; } }

	.table__width-needy-cell {
		min-width: 25rem; } }
