.breadcrumps {
	margin: .63rem auto;  //10px

	&__list {
		display: flex; }

	&__link,
	&__current {
		display: flex;
		align-items: center;
		font-size: .88rem; }  //14px

	&__link {
		margin-right: .63rem;  //10px
		color: $color-primary;

		&::after {
			content: '\/';
			margin-left: .63rem;  //10px
			font-size: 1rem; }

		* {
			color: $color-primary; } }

	&__current {
		color: $color-grey; } }
