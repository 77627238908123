.dialog {
	&__title-custom {
		font-size: 1.25rem; } // 20/16

	&__global-error {
		margin-top: 1rem; }

	.field-with-label {
		margin-right: 0; }

	&--publish {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 22.5rem; // 360/16

		@include media(m) {
			display: block;
			min-height: 12.5rem; } } // 200/16

	@include media(s, 100px) {
		&--sesion .mdc-dialog__surface {
			min-width: 40rem; } } }

.dialog-long-content {
	.mdc-dialog__container {
		align-items: flex-start;
		width: calc(100% - 1rem);  // 1rem from main padding
		max-width: 84.38rem;  //1350px
		padding-top: .5rem;

		@include media(l, 130px) {
			width: calc(100% - 1rem - 15.94rem); //255px - main menu width
			margin: 0 .5rem 0 16.56rem; }  //265px

		@include media(l, 470px) {
			margin: {
				left: calc(16.56rem + (100vw - 1rem - 15.94rem - 84.38rem) / 2);  //calc(265px +(100vw - 1rem -255px -1350px) / 2)
				right: auto; } } }

	.mdc-dialog__content,
	.mdc-dialog__surface {
		overflow: auto;
		width: 100%;
		max-width: none;

		@include media(m) {
			overflow: auto; } }

	.help__show-more {
		top: .25rem; }

	&__close {
		position: absolute;
		right: 1.5rem;
		top: .6rem;
		border: 1px solid white;
		z-index: 101; }

	&--preview .mdc-dialog__content {
		padding-top: 0; } }
