.help {
	position: relative;
	top: 0;
	min-height: 3.13rem;  //50px
	padding: 1rem 1.5rem;
	margin-bottom: 1.5rem;
	background-color: rgba(0, 0, 0, .08);

	h2 {
		padding-right: 10rem; }  //160px

	p {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0; } }

	&__show-more {
		position: absolute;
		top: 1rem;
		right: 1rem;
		height: auto;
		padding: .31rem .5rem;  //5px 8px
		margin: 0;
		font-size: .75rem; }  //12px

	&__list-of-content {
		margin-top: 1rem;

		.link-text:visited {
			color: mix($color-primary, #609); } }

	&__separate-list {
		position: relative;
		padding-left: 1.88rem;  //30px
		margin: 10px 0;

		&--deep {
			padding-left: 0; } }

	.draft-to-components {
		margin-top: 1.56rem; } }  //25px
