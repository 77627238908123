.announcement {
	min-width: 15.63rem;  //250px

	&--date {
		width: 100%;
		max-width: 28.13rem; }  //450px

	&__btn-versions {
		position: relative;
		transform: scale(.8);
		transform-origin: left top; }

	&__part {
		padding-bottom: 1.88rem;  //30px
		border-bottom: 1px dashed $color-border;
		margin-bottom: 1.88rem; }  //30px

	&__part-title {
		max-width: 70%;

		.text--main-header {
			margin: 0 0 .63rem; } }  //10px

	&__part-link {
		display: block;
		margin: 5px 0; } }
