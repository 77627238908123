.grid-custom {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin: 0;

	> .box {
		margin: .63rem; }  //10px

	&__cell input {
		width: 100%; }

	&--center {
		justify-content: center;
		align-items: center; }

	&--center-h {
		justify-content: center; }

	&--center-v {
		align-items: center; }

	&--top {
		align-items: flex-start; }

	&--bottom {
		align-items: flex-end; }

	&--end {
		justify-content: flex-end; }

	&--flex-s,
	&--flex-m,
	&--flex-l {
		display: block; }

	&--flex-s {
		@include media(s) {
			display: flex;
			flex-wrap: wrap; } }

	&--flex-m {
		@include media(m, -100px) {
			display: flex;
			flex-wrap: wrap; } }

	&--flex-l {
		@include media(l) {
			display: flex;
			flex-wrap: wrap; } }

	&--full-width {
		justify-content: space-between;
		flex-wrap: nowrap; }

	&--full-height {
		height: 100%; }

	&--one-row {
		flex-wrap: nowrap; }

	.label {
		display: block;
		margin-bottom: .31rem; }  //5px

	> .field-with-label {
		flex-grow: 1;
		margin-right: 1rem; } }  //16px
