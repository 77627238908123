.next-step-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.88rem;  //30px
	border-bottom: 1px dashed rgba(0, 0, 0, .5);

	&--one-item {
		justify-content: flex-end; } }

.next-step {
	display: flex;
	justify-self: flex-end;
	margin: 0 0 1.88rem;  //30px

	&__content {
		display: flex;
		flex-direction: column;

		* {
			margin: 0; } } }
